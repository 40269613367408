<template>
  <div>
    <div class="controls-panel">
      <div class="row">
        <div class="col-md-6">
          <div class="search-wrapper">
            <img src="@/assets/icons/icon-search.svg" alt="Icon search">
            <input type="text" placeholder="Search" v-model="search.query" @keyup="searchFundingRequests"
                   aria-label="Search" autofocus>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content p-normal">
      <div class="text-center mt-5" v-if="ui.loading === true">
        <div class="spinner-border mt-5" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="table" v-if="ui.loading === false">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">Borrower</th>
            <th scope="col">MLO</th>
            <th scope="col">Lender</th>
            <th scope="col">Wire/Check Total Amount</th>
            <th scope="col">Status / Score</th>
            <th scope="col">Submitted On</th>
            <th scope="col">Due to Salesperson</th>
            <th scope="col">Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(fr, index) in deletedRequests" :key="index">
            <td class="cursor-pointer table-borrower-name" @click="toggleModal('fundingDetails', fr.id)">
              {{ fr.borrowerName }}
            </td>
            <td>
              <div class="agents-avatars" v-if="isGranted('ROLE_BOOKKEEPER')">
                <a v-for="a in fr.agents" :key="fr.id + '_' + a.id" :href="backendUrl('/mlo/account/agent/' + a.id)" target="_blank">
                  <img :src="agentPhoto(a.photo)" :alt="a.name" v-tippy :content="a.name">
                </a>
              </div>
              <div class="agents-avatars" v-else>
                <img v-for="a in fr.agents" :key="fr.id + '_' + a.id" :src="agentPhoto(a.photo)" :alt="a.name" v-tippy :content="a.name">
              </div>
            </td>
            <td>{{ fr.lender }}</td>
            <td>
              <span v-tippy :content="capitalize(fr.type)">
                <b>{{ convertToCurrency(fr.wireAmount) }}</b>
                ({{ formatDate(fr.wireDate) }})
              </span>
            </td>
            <td>
              <template v-if="isGranted(ROLE_BOOKKEEPER)">
                <div class="btn-group">
                  <div class="btn btn-br-default" :class="{
                    'btn-secondary': fr.status === 'pending',
                    'btn-danger': fr.status === 'error',
                    'btn-success': fr.status === 'waiting_for_payment' || fr.status === 'paid',
                  }" @click="toggleModal('scoreModal', fr.id)">
                    {{ fr.statusForHumans }}
                  </div>
                  <div class="btn btn-br-default" :class="{
                    'btn-danger': fr.score < 40,
                    'btn-primary': fr.score >= 40 && fr.score < 70,
                    'btn-success': fr.score >= 70,
                  }" @click="toggleModal('scoreModal', fr.id)">
                    {{ fr.score === null ? loadScore(fr.id) : fr.score }}
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="fr.score === null"/>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="btn btn-br-default" :class="{
                    'btn-secondary': fr.status === 'pending',
                    'btn-danger': fr.status === 'error',
                    'btn-success': fr.status === 'paid' || fr.status === 'waiting_for_payment',
                  }">
                  {{ fr.statusForHumans }}
                </div>
              </template>
            </td>
            <td>{{ formatDate(fr.dateOfSubmission, 'MMM D, Y') }}</td>
            <td>
              <b>{{ convertToCurrency(fr.dueToSalesperson) }}</b>
            </td>
            <td class="actions-buttons">
              <button class="btn btn-primary" @click="toggleModal('fundingDetails', fr.id)">
                Details
              </button>
              <button v-if="fr.isGranted.edit" class="btn btn-secondary" @click="toggleModal('editFunding', fr.id)">
                Edit
              </button>
              <button v-if="fr.isGranted.pay" class="btn btn-success" @click="pay(fr)">
                <span v-if="ui.pay.loading.includes(fr.id)" aria-hidden="true" class="spinner-border spinner-border-sm text-light" role="status"></span>
                <span v-else>Pay</span>
              </button>
            </td>
          </tr>
          <tr v-if="deletedRequests.length === 0">
            <td colspan="8" class="text-center">
              <div class="m-5">
                <strong class="no-result">No borrower yet.</strong><br>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <pagination
        v-if="ui.loading === false"
        class="justify-content-start"
        :from="pagination.from"
        :to="pagination.to"
        :total="pagination.total"
        :page="pagination.page"
        :per-page="pagination.perPage"
        @update="getData"/>
    </div>

    <transition name="component-fade" mode="out-in">
      <score-modal
        :funding-request-id="selectedFundingRequestId"
        v-if="modals.scoreModal"
        @close="toggleModal"/>
    </transition>
    <transition name="component-fade" mode="out-in">
      <funding-details-modal
        :funding-request-id="selectedFundingRequestId"
        v-if="modals.fundingDetails === true"
        @close="toggleModal"
        @editFunding="modals.editFunding = true"/>
    </transition>
    <transition name="component-fade" mode="out-in">
      <edit-funding-modal
        :funding-request-id="selectedFundingRequestId"
        v-if="modals.editFunding"
        @close="toggleModal"/>
    </transition>
  </div>
</template>

<script>
import FundingDetailsModal from "./modals/FundingDetailsModal";
import Pagination from "../../components/Pagination";
import ScoreModal from "./modals/ScoreModal";
import EditFundingModal from "./modals/EditFundingModal";

export default {
  name: 'FundingRequestDeleted',
  components: {Pagination, FundingDetailsModal, ScoreModal, EditFundingModal},
  data() {
    return {
      deletedRequests: {},
      selectedCount: 0,
      selectedFundingRequestId: null,
      modals: {
        fundingDetails: false,
        scoreModal: false,
        editFunding: false
      },
      ui: {
        loading: false,
        form: {
          restoring: false
        },
        pay: {loading: []}
      },
      search: {
        timer: null,
        query: null
      },
      payment: {
        customFee: false
      },
    }
  },
  methods: {
    getData(pagination = null) {
      this.ui.loading = true;
      let params = pagination ? pagination : this.pagination
      params.query = this.search.query
      params.ids = this.$store.getters.getAutoPrecessedFundingIds
      this.$http
        .get('/api/v1/funding-requests/check-wires-results', { params: params })
        .then(res => {
          this.deletedRequests = res.data.processedRequests
          this.pagination = res.data.pagination
          this.ui.loading = false;
        })
        .catch()
    },
    toggleModal(modalName, fundingId = null, updateTableData = false) {
      this.modals[modalName] = !this.modals[modalName]
      this.selectedFundingRequestId = fundingId
      if (updateTableData) {
        this.getData()
      }
    },
    getScoreClass(score) {
      let className = '';

      if (score < 40) { className = 'funding-score_failed' }
      else if (score < 70) { className = 'funding-score_secondary' }
      else if (score >= 70) { className = 'funding-score_success' }

      return className;
    },
    searchFundingRequests() {
      if (this.search.timer) {
        clearTimeout(this.search.timer)
      }

      this.search.timer = setTimeout(() => {
        this.getData()
      }, 800)
    },
    showSelectNav() {
      this.showSelect = !this.showSelect;
    },
    getRequestsByStatus(status) {
      this.status = status
      this.getPendingRequests()
    },
    selectAll(val) {
      if (val === false) {
        this.pendingRequests.map((request) => {
          request.checked = true;
          this.selectedCount++;
        })
      } else {
        this.pendingRequests.map((request) => {
          request.checked = false;
          this.selectedCount--;
        })
      }
    },
    deleteFunding(id, deleteSelected = false) {
      if (confirm('Are you sure?')) {
        this.$http
          .delete(`/api/v1/funding-requests/delete/${id}`)
          .then(() => {
            if (deleteSelected && this.getSelectedIds().length > 0) {
              this.toggleCheckboxItem(id)
              this.deleteFunding(this.getSelectedIds()[0], true)
            }

            this.getPendingRequests()
          })
      }
    },
    pay(fr) {
      this.ui.pay.loading.push(fr.id);
      this.$http
        .post(`/api/v1/funding_requests/pay/${fr.id}`)
        .then(() => {
          this.deletedRequests = this.deletedRequests.filter((f) => f.id !== fr.id);
          this.alertSuccess(`${fr.borrowerName} has been successfully paid`)
        })
        .catch((e) => {
          let err = this.getFirstErrorFromResponse(e.response)
          this.alertError(err)
        }).finally(() => {
        this.ui.pay.loading = this.ui.pay.loading.filter(id => id !== fr.id)
      })
    },
    openPaymentCustomModal(id) {
      this.selectedFundingRequestId = id;
      this.modals.payment = true;
      this.payment.customFee = true;
    },
    toggleCheckboxItem(id) {
      this.selectedCount = 0
      this.pendingRequests.map(item => {
        if (item.id === id) {
          item.checked = !item.checked
        }

        if (item.checked)
          this.selectedCount++
      })

      let checkbox = document.getElementById('checkbox-select')
      if (this.selectedCount > 0 && this.selectedCount < this.pendingRequests.length) {
        checkbox.indeterminate = true
      } else if (this.selectedCount === this.pendingRequests.length) {
        checkbox.indeterminate = false
        checkbox.checked = true
      } else {
        checkbox.indeterminate = false
        checkbox.checked = false
      }
    },
    getSelectedIds() {
      let ids = [];

      this.pendingRequests.map(item => {
        if (item.checked) {
          ids.push(item.id)
        }
      })

      return ids
    },
    loadScore(id) {
      this.$http.get(`/api/v1/funding_requests/get-score/${id}`)
        .then((res) => {
          this.pendingRequests.map((request) => {
            if (request.id === id) {
              request.score = Number.parseInt(res.data.score);
            }
          })
        })
        .catch()
    },
  },
  computed: {
    currentPage() {
      return this.$route.name
    },
    allowEdit() {
      return this.selectedCount === 1
    }
  },
  beforeMount() {
    if (this.$store.getters.getAutoPrecessedFundingIds.length < 1) {
      this.$router.push({name: 'funding_requests_pending'})
    }

    this.getData();
  },
  beforeDestroy() {
    this.$store.dispatch('SET_AUTO_PROCESSED_FUNDING_IDS', [])
  }
}
</script>

<style lang="scss" scoped>
.table {
  tbody {
    tr {
      vertical-align: middle;

      td {
        color: rgba(0, 0, 0, .8);
        font-size: 14px;
        letter-spacing: 0;
        line-height: 21px;

        &:not(:first-child) {
          padding-left: 0;
        }
      }

      .btn {
        max-height: 32px;
        height: 32px;
        font-size: 12px;
        line-height: 1.5;
      }

      .agents-avatars {
        img {
          height: 34px;
          width: 34px;
          border: 2px solid #F6F6F6;
          border-radius: 50%;

          &:nth-child(n + 2) {
            margin-left: -5px;
          }
        }
      }
    }
  }

  .actions-buttons {
    .btn {
      margin-right: 0.25rem;
    }
  }
}

.modal {
  .tab-list {
    padding: 0;

    li {
      &:first-child {
        margin: 0;
      }
    }
  }
}

.form-check-input {
  border-width: 2px;
}
</style>
